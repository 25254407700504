<template>
  <div style="margin: 0 0 50px 0">
 
    <v-btn @click="TEST()"> test </v-btn>
 
  </div>
</template>
 
<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
 
import { freeSet } from "@coreui/icons";
import config from "../config";
const axios = require("axios");
 
export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
 
    }
  },
  mounted() {
 
  },
  methods: {
    TEST(){
      console.log("||||||||||||",this.$store.state.popupregis_buff)
      // this.$store.state.popupregis_buff = true
      this.$router.push('/memberww')
    }
  },
};
</script>